import { Button, Space, Select, Divider, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export interface FilterDropdownProps {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  close: any;
}

export interface SelectedOptions {
  label: string | null;
  value: string;
}

export const FilterByStatus = (options: SelectedOptions[]) => {
  return ({ setSelectedKeys, selectedKeys, confirm, close }: FilterDropdownProps) => {
    const { t } = useTranslation();

    const defaultComponent = () => (
      <div style={{ padding: 8 }}>
        <Select
          value={selectedKeys[0]}
          style={{ width: '100%' }}
          placeholder={t('status.all')}
          onChange={(e) => {
            setSelectedKeys(e ? [e] : []);
          }}
        >
          {options.map((item) => (
            <Select.Option key={`opt-${item.value}`} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
        <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: true });
            }}
          >
            {t('general.filter')}
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              confirm({ closeDropdown: true });
            }}
            size="small"
          >
            {t('general.clear')}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            {t('general.close')}
          </Button>
        </Space>
      </div>
    );

    return defaultComponent();
  };
};

export const FilterByIcon = (filtered: boolean) => (
  <SearchOutlined style={{ color: filtered ? '#fc6634' : undefined }} />
);

export const FilterByRegex = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  close,
}: FilterDropdownProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={t('general.searchBy') || ''}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value, '$regex'] : [])
        }
        style={{ width: '100%' }}
      />
      <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />
      <Space>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}
          icon={<SearchOutlined />}
        >
          {t('general.filter')}
        </Button>
        <Button
          onClick={() => {
            setSelectedKeys([]);
            confirm({ closeDropdown: true });
          }}
          size="small"
        >
          {t('general.clear')}
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          {t('general.close')}
        </Button>
      </Space>
    </div>
  );
};
